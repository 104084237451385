import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Box } from "@mui/material";
import { toast } from "react-toastify";

import { Status, StatusFr, Type, TypeFr } from "../../api/enum";
import { ILeave } from "../../api/types";

import { QUERY_KEYS } from "../../constants";

import moment from "moment";
import { getAllLeave, updateLeave } from "../../api/leaveApi";

const AdminLeave = () => {
  const queryClient = useQueryClient();

  const columns2: GridColDef<ILeave>[] = [
    {
      field: "FirstName",
      headerName: "Prénom",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.user.FirstName,
    },
    {
      field: "LastName",
      headerName: "Nom",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.user.LastName,
    },
    {
      field: "startDate",
      headerName: "Date de début ",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: true,
      type: "dateTime",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
    },
    {
      field: "duration",
      headerName: "Nombre de jours ",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: true,
      type: "number",
    },
    {
      field: "type",
      type: "singleSelect",
      valueOptions: [
        { label: "Congé payé", value: Type.CP },
        { label: "Congé non payé", value: Type.CNP },
      ],
      headerName: "Type de congé",
      width: 200,
      editable: true,
      valueFormatter: (params) => TypeFr[params.value] || "",
      headerClassName: "super-app-theme--header",
    },

    {
      field: "status",
      type: "singleSelect",
      valueOptions: [
        { label: "En attente", value: Status.PENDING },
        { label: "Rejeté", value: Status.REJECTED },
        { label: "Accepté", value: Status.FULFIELD },
      ],
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      editable: true,
      valueFormatter: (params) => StatusFr[params.value] || "",
    },
  ];

  const leaveQuery = useQuery([QUERY_KEYS.LEAVES], () =>
    getAllLeave({ status: Status.PENDING })
  );
  const { mutate: editLeave } = useMutation(updateLeave, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.LEAVES]);
      toast.success("Status mis a jour  avec succée");
    },
    onError: (error: any) => {
      toast.error("erreur");
    },
  });

  return (
    <>
      <Box
        sx={{
          height: 300,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "rgb(91, 99, 71)",
          },
        }}
      >
        <h1 style={{ color: "black", marginLeft: "5rem", textAlign: "center" }}>
          Demandes des congés
        </h1>

        <div></div>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={leaveQuery?.data || []}
            loading={leaveQuery.isLoading}
            error={leaveQuery.error}
            columns={columns2}
            pageSize={5}
            rowsPerPageOptions={[5]}
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={(newRow) => {
              editLeave({
                userId: newRow.user.id,
                id: newRow.id,
                body: {
                  startDate: newRow.startDate,
                  duration: newRow.duration,
                  status: newRow.status,
                },
              });

              return newRow;
            }}
          />
        </div>
      </Box>
    </>
  );
};

export default AdminLeave;
