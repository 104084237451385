export enum Role {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  USER = 'user',
  CONTROLLER = 'controller',
  enum = 'enum'
}

export enum Status {
  PENDING = 'pending',
  REJECTED = 'rejected',
  FULFIELD = 'fulfield',
}



export enum Type {
  CP = 'paid leave',
  CNP= 'unpaid leave',
  
}

export const TypeFr: Record<string, string> = {
  [Type.CP]: 'Congé payé',
  [Type.CNP]: 'Congé non payé',
  
}

export enum Confirm {
  CONFIRM = 'confirm',
  CANCEL= 'cancel'
}
export const ConfirmFr: Record<string, string> = {
  [Confirm.CONFIRM]: 'Confirmer',
  [Confirm.CANCEL]: 'Annuler',
  
}
export const StatusFr: Record<string, string> = {
  [Status.PENDING]: 'En attente',
  [Status.REJECTED]: 'Rejeté',
  [Status.FULFIELD]: 'Accepté',
}