import axios from 'axios';
import { Status } from './enum';


import { IAcc, ISalary } from './types';

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const accApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

accApi.defaults.headers.common['Content-Type'] = 'application/json';

export interface CreateAccBody {
  id: number;
  amountAcc:string;
 
}

export const createAcc = async (acc:CreateAccBody ) => {
  const response = await accApi.post<IAcc>('acc', acc);
  return response.data;
};

export const getMe = async (filter: AccFilters): Promise<IAcc[]> => {
  const response = await accApi.get<IAcc[]>('/acc/one', {
    params: filter
  });
  return response.data;
};


interface AccFilters {
  date: Date;
  status?: Status
}
export const getAllacc = async (filter: AccFilters): Promise<IAcc[]> => {
  const response = await accApi.get<IAcc[]>('/acc', {
    params: filter
  });
  return response.data;
};

export const getAll2 = async (): Promise<IAcc[]> => {
  const response = await accApi.get<IAcc[]>('/acc/acc2');
  return response.data;
};



export const updateAcc = async (params: { id: number; body: any }) => {
  const response = await accApi.put<IAcc>(`acc/${params.id}`, params.body);
  return response.data;
};