import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import ReactDOM from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthContextProvider } from "./context";
import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      suspense: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
        <ConfirmProvider
          defaultOptions={{
            confirmationText: "Accepter",
            cancellationText: "Annuler",
          }}
        >
          <Router>
            <AuthContextProvider>
              <ProSidebarProvider>
                <App />
              </ProSidebarProvider>
            </AuthContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Router>
        </ConfirmProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
