import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Box } from "@mui/material";
import { toast } from "react-toastify";

import { Status, StatusFr } from "../../api/enum";
import { IAcc } from "../../api/types";

import { QUERY_KEYS } from "../../constants";

import moment from "moment";
import { getAllacc, updateAcc } from "../../api/accApi";

const AdminAcc = () => {
  const queryClient = useQueryClient();

  const columns2: GridColDef<IAcc>[] = [
    {
      field: "FirstName",
      headerName: "Prénom",
      width: 200,
      valueGetter: (params) => params.row.user.FirstName,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "LastName",
      headerName: "Nom",
      width: 200,
      valueGetter: (params) => params.row.user.LastName,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amountAcc",
      headerName: "Montant",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      type: "singleSelect",
      valueOptions: [
        { label: "En attente", value: Status.PENDING },
        { label: "Rejeté", value: Status.REJECTED },
        { label: "Accepté", value: Status.FULFIELD },
      ],
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 100,
      editable: true,
      valueFormatter: (params) => StatusFr[params.value] || "",
    },

    {
      field: "createdAt",
      headerName: "Crée le",
      headerClassName: "super-app-theme--header",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
    },
    {
      field: "comment",
      headerName: "Commentaire",
      width: 400,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
  ];

  const [filterDate, setFilterDate] = React.useState<Date>(new Date());

  const accsQuery = useQuery([QUERY_KEYS.ACCS, filterDate], () =>
    getAllacc({ date: filterDate, status: Status.PENDING })
  );

  const { mutate: editAcc } = useMutation(updateAcc, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.ACCS]);
      toast.success("Status mis a jour  avec succée");
    },
    onError: (error: any) => {
      toast.error("erreur");
    },
  });

  return (
    <>
      <Box
        sx={{
          height: 300,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "rgb(91, 99, 71)",
          },
        }}
      >
        <h1 style={{ color: "black", marginLeft: "5rem", textAlign: "center" }}>
          Demandes des accomptes
        </h1>
        <div></div>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={accsQuery?.data || []}
            loading={accsQuery.isLoading}
            error={accsQuery.error}
            columns={columns2}
            pageSize={5}
            rowsPerPageOptions={[5]}
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={(newRow) => {
              editAcc({
                id: newRow.id,
                body: {
                  amountAcc: newRow.amountAcc,
                  status: newRow.status,
                  comment: newRow.comment,
                },
              });

              return newRow;
            }}
          />
        </div>
      </Box>
    </>
  );
};

export default AdminAcc;
