import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import Layout from "../components/layout";
import RequireUnauthenticated from "../components/RequireUnauthenticated";
import RequireUser from "../components/requireUser";
import Accompte from "../pages/Accomptes/Accompte";
import Create from "../pages/Create";

import ListeAcc from "../pages/Accomptes/ListeAcc";

import AdminAcc from "../pages/Accomptes/AdminAcc";
import AdminLeave from "../pages/Congés/AdminLeave";

import ListeLeaves from "../pages/Congés/ListeLeaves";

const Loadable =
  (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<FullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    );

const LoginPage = Loadable(lazy(() => import("../pages/Login")));
const Teams = Loadable(lazy(() => import("../pages/Teams")));

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const ProfilePage = Loadable(lazy(() => import("../pages/profile")));
const UnauthorizePage = Loadable(lazy(() => import("../pages/unauthorize")));

const CreateEntry = Loadable(
  lazy(() => import("../pages/Entries/CreateEntry"))
);
const Leave = Loadable(lazy(() => import("../pages/Congés/Leave")));

const ListEntries = Loadable(lazy(() => import("../pages/Entries/Entries")));
const ListEntriesdelay = Loadable(
  lazy(() => import("../pages/Entries/Entriesdelay"))
);
const Absences = Loadable(lazy(() => import("../pages/Absences/Absences")));
const CreateAbs = Loadable(lazy(() => import("../pages/Absences/CreateAbs")));
const HistoriqueAcc= Loadable(lazy(() => import("../pages/Accomptes/HistoriqueAcc")));
const HistoriqueLeave= Loadable(lazy(() => import("../pages/Congés/HistoriqueLeave")));
const Holidays= Loadable(lazy(() => import("../pages/Holidays/Holidays")));


const authRoutes: RouteObject = {
  path: "*",
  element: <RequireUnauthenticated />,
  children: [
    {
      path: "login",
      element: <LoginPage />,
    },
  ],
};

const normalRoutes: RouteObject = {
  path: "*",
  element: <RequireUser />,
  children: [
    {
      element: <Layout />,
      children: [
        {
          element: <RequireUser allowedRoles={["super_admin", "admin"]} />,
          children: [
            {
              index: true,
              element: <Dashboard />,
            },
          ],
        },

        {
          path: "teams",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <Teams />,
            },
            {
              path: "listeofaccs",
              element: <AdminAcc />,
            },
            {
              path: "historiqueofaccs",
              element: <HistoriqueAcc />,
            },
            {
              path: "adminleave",
              element: <AdminLeave />,
            },
            {
              path: "historiqueleave",
              element: <HistoriqueLeave />,
            },
            {
              path: "absences",
              element: <Absences />,
            },
            {
              path: "create",
              element: <RequireUser />,
              children: [
                {
                  path: "",
                  element: <Create />,
                },
              ],
            },
          ],
        },
        {
          path: "liste",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <ListeAcc />,
            },
            {
              path: "accompte",
              element: <RequireUser />,
              children: [
                {
                  path: "",
                  element: <Accompte />,
                },
              ],
            },
          ],
        },

        {
          path: "listeofleaves",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <ListeLeaves />,
            },
            {
              path: "leave",
              element: <RequireUser />,
              children: [
                {
                  path: "",
                  element: <Leave />,
                },
              ],
            },
          ],
        },
        {
          path: "entries",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <ListEntries />,
            },
            {
              path: "delay",
              element: <ListEntriesdelay />,
            },
            {
              path: "create",
              element: <CreateEntry />,
            },
          ],
        },
        {
          path: "holidays",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <Holidays />,
            },
            
          ],
        },

        {
          path: "absences",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <Absences />,
            },

            {
              path: "createAbs",
              element: <CreateAbs />,
            },
          ],
        },
        {
          path: "profile",
          element: <RequireUser />,
          children: [
            {
              path: "",
              element: <ProfilePage />,
            },
          ],
        },
        {
          path: "unauthorized",
          element: <UnauthorizePage />,
        },
      ],
    },
  ],
};

const routes: RouteObject[] = [authRoutes, normalRoutes];

export default routes;
