import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useQuery } from "@tanstack/react-query";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getAbs2 } from "../../api/absApi";
import { getMe } from "../../api/accApi";
import { getMeauth } from "../../api/authApi";
import { getEntries2 } from "../../api/entriesApi";
import { Status, StatusFr } from "../../api/enum";
import { IAcc } from "../../api/types";
import { QUERY_KEYS } from "../../constants";

const ListeAcc = () => {
  const columns: GridColDef<IAcc>[] = [
    {
      field: "amountAcc",
      headerName: "Montant",
      width: 200,
      headerClassName: "super-app-theme--header",
      flex:1
    },
    {
      field: "status",
      type: "singleSelect",
      valueOptions: [
        { label: "En attente", value: Status.PENDING },
        { label: "Rejeté", value: Status.REJECTED },
        { label: "Accepté", value: Status.FULFIELD },
      ],
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      flex:1,
      valueFormatter: (params) => StatusFr[params.value] || "",
      
    },
    {
      field: "comment",
      headerName: "Commentaire",
      width: 400,
      editable: true,
      headerClassName: "super-app-theme--header",
      flex:1
    },
    {
      field: "created_at",
      headerName: "Crée le",
      headerClassName: "super-app-theme--header",
      width: 200,
      flex:1,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
    },
  ];

  const [filterDate, setFilterDate] = useState<Date>(new Date());

  const accsQuery = useQuery([QUERY_KEYS.ACCS], () =>
    getMe({ date: new Date() })
  );
  const userQuery = useQuery([QUERY_KEYS.AUTH_USER], getMeauth);

  const entriesQuery = useQuery([QUERY_KEYS.ENTRIES], () =>
    getEntries2(new Date())
  );
  const absQuery = useQuery([QUERY_KEYS.ABSENCES], () =>
    getAbs2({
      date: new Date(),
      status: Status.FULFIELD,
    })
  );

  const totalAccs = useMemo(
    () =>
      accsQuery.data?.reduce(
        (sum, acc) =>
          (sum += acc.status == Status.FULFIELD ? +acc.amountAcc : 0),
        0
      ) || 0,
    [accsQuery.data]
  );

  const totalAbs = useMemo(
    () => absQuery.data?.reduce((sum, abs) => (sum += +abs.lose), 0) || 0,
    [absQuery.data]
  );
  const totalEntries = useMemo(
    () =>
      entriesQuery.data?.reduce((sum, entry) => (sum += +entry.lose), 0) || 0,
    [entriesQuery.data]
  );

  const salary = useMemo(
    () =>
      userQuery.data?.salaries[userQuery.data.salaries.length - 1]
        .amountSalary || 0,
    [userQuery.data]
  );

  return (
    <Box
    sx={{
      height: 300,
      width: "100%",
      "& .super-app-theme--header": {
        backgroundColor: "rgb(91, 99, 71)",
      },
    }}
    >
      <h1 style={{ color: "black", marginLeft: "5rem", textAlign: "center" }}>
        Mes accomptes
        <Link to="/liste/accompte">
          <AddCircleTwoToneIcon></AddCircleTwoToneIcon>
        </Link>
      </h1>

      <div>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          views={["month", "year"]}
          label=""
          value={filterDate}
          onChange={(newValue) => {
            setFilterDate(newValue || new Date());
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <h3>Reste (TND) :   {salary - (totalAccs + totalAbs + totalEntries)}</h3>
      </div>

      <Box
        sx={{
          gridTemplateColumns: "repeat(1,2fr)",
          width: "100%",
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        
       
        
        <div style={{ height: 400,width: "100%" }}>
          <DataGrid
            rows={accsQuery?.data || []}
            loading={accsQuery.isLoading}
            error={accsQuery.error}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
        
      </Box>
    </Box>
  );
};

export default ListeAcc;
