import axios from "axios";

import { Status, Type } from "./enum";

import { ILeave } from "./types";

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const leaveApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

leaveApi.defaults.headers.common["Content-Type"] = "application/json";

export interface CreateLeaveBody {
  id: number;
  userId: number;
  status: Status;
  startDate: Date;
  duration: number;
  comment: string;
  type: Type;
  createdAt: string;
}

export const createLeave = async (leave: CreateLeaveBody): Promise<void> => {
  const response = await leaveApi.post("/leave", leave);
  return response.data;
};

export const getMe = async (filterDate: Date): Promise<ILeave[]> => {
  const response = await leaveApi.get<ILeave[]>("/leave/one", {
    params: {
      date: filterDate,
    },
  });
  return response.data;
};

interface LeaveFilters {
  date: Date;
  status?: Status;
}

interface LeaveFilters2 {
  status?: Status;
}
export const getAllLeave = async (filter: LeaveFilters2): Promise<ILeave[]> => {
  const response = await leaveApi.get<ILeave[]>("/leave/All", {
    params: filter,
  });
  return response.data;
};

export const getHistOfLeave = async (
  filter: LeaveFilters
): Promise<ILeave[]> => {
  const response = await leaveApi.get<ILeave[]>("/leave", {
    params: filter,
  });
  return response.data;
};

export interface UdpdateLeaveBody {
  startDate?: Date;
  duration?: number;
  comment?: string;
  status?: Status;
}

export const updateLeave = async (params: {
  id: number;
  userId: number;
  body: UdpdateLeaveBody;
}) => {
  const response = await leaveApi.put<ILeave>(
    `leave/${params.id}/${params.userId}`,
    params.body
  );
  return response.data;
};
