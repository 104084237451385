import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";
import Header from "./Header";

const Layout = () => {
  return (
    <>
      <Header />
      <Box display={"flex"} flexDirection="row">
        <AppBar />
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
