import axios from "axios";
import { Status } from "./enum";

import { IAbs } from "./types";

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const absApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

absApi.defaults.headers.common["Content-Type"] = "application/json";

export interface CreateAbsBody {
  userId: number;
  
  comment: string;
}
interface AbsFilters {
  date: Date;
  status?: Status
 
}


export const getAbs = async (filterDate: Date): Promise<IAbs[]> => {
  const response = await absApi.get<IAbs[]>("/absences", {
    params: {
      date: filterDate
    }
  });
  return response.data;
};
export const getAbs2 = async (filter: AbsFilters): Promise<IAbs[]> => {
  const response = await absApi.get<IAbs[]>("/absences/dash", {
    params: filter
  });
  return response.data;
};


export const createAbs = async (body: CreateAbsBody): Promise<void> => {
  const response = await absApi.post("/absences", body);
  return response.data;
};


export const updateAbs = async (params: { id: number; body: any }) => {
  
  const response = await absApi.put<IAbs>(`/absences/${params.id}`, params.body);
  return response.data;
};

export const removeAbs = async (id: number) => {
  const response = await absApi.delete<IAbs>(`absences/${id}`);
  return response.data;
}