import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  Container,
  FormControl,
  TextField
} from '@mui/material';
import { Send, KeyboardArrowRight } from '@mui/icons-material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useStateContext } from '../context';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
//import FormInput from '../components/FormInput';
import { createUser } from '../api/userApi';
import { Role } from '../api/enum';
import * as yup from 'yup';


import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { parsePhoneNumber } from 'libphonenumber-js';



const styles = {
  btn: {
    fondtSize: 60,
    backgroundColor: 'black'
  },
  title: {
    textDecoration: 'underline',
    marginBottom: 20
  }
};

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: black;
  color: #2363eb;
  font-weight: 500;

  &:hover {
    background-color: green;
    transform: translateY(-2px);
  }
`;



const createSchema = yup.object().shape({
  FirstName: yup
    .string()
    .required('required')
    .min(3, 'Le prénom doit contenir au moins 3 caractére'),
  LastName: yup.string().required('required').min(3, 'Le Nom doit contenir au moins 3 caractére'),
  email: yup.string().email('invalid email').required('required'),
  Phone: yup.string().min(8, 'Numéro non attribué ').required('required'),
  fonction: yup.string().required('required'),
  Rib: yup.string().required('required'),
  amount: yup.number().required('required'),
  password: yup
    .string()
    .required('required')
    .min(5, 'Le mot de passe doit comporter plus de 5 caractères')
    .max(32, 'Le mot de passe doit comporter moins de 32 caractères'),
    confirmPwd: yup.string()
    .required('required')
    .oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas'),
  role: yup.string().required('required')
});

const Create = () => {
  

  const methods = useForm({
    resolver: yupResolver(createSchema),
  });
  const navigate = useNavigate();
  //  API Create Mutation
  const { mutate: createuser, isLoading } = useMutation(createUser, {
    onSuccess: () => {
      reset();
      toast.success('utilisateur crée avec succée');
      navigate('/teams')
    },
    onError: (error: any) => {
      if (Array.isArray((error).response.data.error)) {
        (error).response.data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right'
          })
        );
      } else {
        toast.error((error).response.data.message, {
          position: 'top-right'
        });
      }
    }
  });

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = methods;
 
  const onSubmitHandler = (values: any) => {
    // 👇 Executing the createUser Mutation
    const phone = parsePhoneNumber(values.Phone, "TN")
    createuser({...values, Phone: phone.nationalNumber});
    
    
  };

  return (
    <Container>
      <Typography
        sx={styles.title}
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Créer un nouveau utilisateur
      </Typography>

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}

          sx={{
            '& > :not(style)': { m: 1, width: '40ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            {...register('FirstName')}
            error={Boolean(errors.FirstName?.message)}
            helperText={<>{errors.FirstName?.message}</>}
            label="Prénom"
            type="string"
            fullWidth

          />
          <TextField
            autoFocus
            margin="dense"
            {...register('LastName')}
            error={Boolean(errors.LastName?.message)}
            helperText={<>{errors.LastName?.message}</>}
            label="Nom"
            type="string"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            {...register('fonction')}
            error={Boolean(errors.fonction?.message)}
            helperText={<>{errors.fonction?.message}</>}
            label="Fonction"
            type="string"
            fullWidth
            variant="outlined"
          />

          <TextField
            autoFocus
            margin="dense"
            {...register('Rib')}
            error={Boolean(errors.fonction?.message)}
            helperText={<>{errors.fonction?.message}</>}
            label="RIB"
            type="string"
            fullWidth
            variant="outlined"
          />

          <TextField
            autoFocus
            margin="dense"
            {...register('amount')}
            error={Boolean(errors.amount?.message)}
            helperText={<>{errors.amount?.message}</>}
            label="Salaire de base"
            type="string"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            {...register('email')}
            error={Boolean(errors.email?.message)}
            helperText={<>{errors.email?.message}</>}
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
          />
          <Controller
            name="Phone"
            control={control}
            rules={{ validate: matchIsValidTel }}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...register('Phone')}

                {...field}
                onlyCountries={["TN"]}
                defaultCountry={"TN"}
              
                error={Boolean(errors.Phone?.message)}
                helperText={<>{errors.Phone?.message}</>}
              />
            )}
          />
          <TextField
            autoFocus
            margin="dense"
            {...register('password')}
            error={Boolean(errors.password?.message)}
            helperText={<>{errors.password?.message}</>}
            label="Mot de passe"
            type="password"
            fullWidth
            variant="outlined"
          />
           <TextField
            autoFocus
            margin="dense"
            {...register('confirmPwd')}
            error={Boolean(errors. confirmPwd?.message)}
            helperText={<>{errors. confirmPwd?.message}</>}
            label="Confirmer Mot de passe"
            type="password"
            fullWidth
            variant="outlined"
          />
         

          <FormControl fullWidth>
            <InputLabel id="role-label" htmlFor="role">
              Role
            </InputLabel>
            <Select labelId="role-label" label="Role" {...register('role')}>
              <MenuItem value={Role.USER}>User</MenuItem>
              <MenuItem value={Role.ADMIN}>Admin</MenuItem>
              <MenuItem value={Role.SUPER_ADMIN}>Super_Admin</MenuItem>
              <MenuItem value={Role.CONTROLLER}>Controller</MenuItem>
            </Select>
          </FormControl>

          <br></br>

          <LoadingButton
            sx={styles.btn}
            type="submit"
            color="secondary"
            variant="contained"
            startIcon={<Send />}
            endIcon={<KeyboardArrowRight />}
            loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
};

export default Create;
