import axios from 'axios';
import { LoginInput } from '../pages/Login';

import { GenericResponse, ILoginResponse, IUser, IUserResponse } from './types';

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

authApi.defaults.headers.common['Content-Type'] = 'application/json';

export const refreshAccessTokenFn = async () => {
  const response = await authApi.get<ILoginResponse>('auth/login');
  return response.data;
};

authApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error.response.data.message as string;
    if (errMessage.includes('not logged in') && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessTokenFn();
      return authApi(originalRequest);
    }
    if (error.response.data.message.includes('not refresh')) {
      document.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const loginUserFn = async (user: LoginInput) => {
  const response = await authApi.post<ILoginResponse>('auth/login', user);
  return response.data;
};

export const logoutUserFn = async () => {
  const response = await authApi.get<GenericResponse>('auth/logout');
  return response.data;
};

export const getMeauth = async (): Promise<IUser> => {
  const response = await authApi.get<IUser>('/users/one');
  return response.data;
};

export const getAll = async (): Promise<IUser[]> => {
  const response = await authApi.get<IUser[]>('/users');
  return response.data;
};
