import axios from 'axios';
import { Role } from './enum';

import { IUser } from './types';

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const userApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

userApi.defaults.headers.common['Content-Type'] = 'application/json';

export interface CreateUserBody {
  FirstName: string;
  LastName: string;
  fonction: string;
  role: Role;
  Phone: string;
  email: string;
  password: string;
  confirmPwd:string;
}

export const createUser = async (user: CreateUserBody) => {
  const response = await userApi.post<IUser>('users', user);
  return response.data;
};

export const updateUser = async (params: { id: number; body: any }) => {
  
  const response = await userApi.put<IUser>(`users/${params.id}`, params.body);
  return response.data;
};

export const removeUser = async (id: number) => {
  const response = await userApi.delete<IUser>(`users/${id}`);
  return response.data;
};
