import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useQuery } from "@tanstack/react-query";

import { Box, TextField } from "@mui/material";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Status, StatusFr, TypeFr } from "../../api/enum";
import { getMe } from "../../api/leaveApi";

import { getMeauth } from "../../api/authApi";
import { ILeave, IUser } from "../../api/types";
import { QUERY_KEYS } from "../../constants";

const Liste = () => {
  const columns: GridColDef<ILeave>[] = [
    {
      field: "startDate",
      headerName: "Date de début ",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
      headerClassName: "super-app-theme--header",
      flex:1,
    },
    {
      field: "duration",
      headerName: "Nombre de jours ",
      width: 200,

      headerClassName: "super-app-theme--header",
      flex:1,
      
    },
    {
      field: "status",
      type: "singleSelect",

      headerName: "Status",
      width: 100,
      valueFormatter: (params) => StatusFr[params.value] || "",
      headerClassName: "super-app-theme--header",
      flex:1,
    },
    {
      field: "type",
      type: "singleSelect",

      headerName: "Type de congé",
      width: 200,
      valueFormatter: (params) => TypeFr[params.value] || "",
      headerClassName: "super-app-theme--header",
      flex:1,
    },

    
    {
      field: "createdAt",
      headerName: "Crée le",
      width: 150,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
      headerClassName: "super-app-theme--header",
      flex:1,
    },
  ];
  



  const [filterDate, setFilterDate] = React.useState<Date>(new Date());
  const leaveQuery = useQuery([QUERY_KEYS.LEAVES, filterDate], () =>
    getMe(filterDate)
  );
  const query = useQuery([QUERY_KEYS.AUTH_USER], getMeauth);
  return (
    <Box
     sx={{
        height: 300,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "rgb(91, 99, 71)",
        },
      }}
    >  
      <h1 style={{ color: "black", marginLeft: "5rem", textAlign: "center" }}>
        Mes congés{" "}
        <Link to="/listeofleaves/leave">
          <AddCircleTwoToneIcon></AddCircleTwoToneIcon>
        </Link>
      </h1>

      <div>
        <DatePicker
          views={["month", "year"]}
          label=""
          value={filterDate}
          onChange={(newValue) => {
            setFilterDate(newValue || new Date());
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <h3>Solde de congé : {query.data?.leaveBalance} Jours</h3>
      </div>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={leaveQuery?.data || []}
          loading={leaveQuery.isLoading}
          error={leaveQuery.error}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
         
      </div>
    </Box>
  );
};

export default Liste;
