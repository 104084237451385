import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getMeauth } from "../api/authApi";
import { QUERY_KEYS } from "../constants";
import { useStateContext } from "../context";
import FullScreenLoader from "./FullScreenLoader";

const RequireUser = ({ allowedRoles = [] }: { allowedRoles?: string[] }) => {
  const [cookies] = useCookies(["logged_in"]);
  const location = useLocation();
  const stateContext = useStateContext();

  const {
    isLoading,
    isFetching,
    data: user,
  } = useQuery([QUERY_KEYS.AUTH_USER], getMeauth, {
    retry: 1,
    onSuccess: (data) => {
      stateContext.dispatch({ type: "SET_USER", payload: data });
    },
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <FullScreenLoader />;
  }

  return user &&
    (allowedRoles.includes(user?.role as string) ||
      allowedRoles.length === 0) ? (
    <Outlet />
  ) : cookies.logged_in || user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default RequireUser;
