import { useCookies } from 'react-cookie';
import { useQuery } from '@tanstack/react-query';
import { Navigate, Outlet } from 'react-router-dom';
import {  getMeauth } from '../api/authApi';
import { useStateContext } from '../context';
import FullScreenLoader from './FullScreenLoader';
import { QUERY_KEYS } from '../constants';

const RequireUnauthenticated = () => {
  const [cookies] = useCookies(['logged_in']);
  const stateContext = useStateContext();

  const {
    isLoading,
    isFetching,
    data: user
  } = useQuery([QUERY_KEYS.AUTH_USER], getMeauth, {
    retry: 1,
    onSuccess: (data) => {
      stateContext.dispatch({ type: 'SET_USER', payload: data });
    }
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <FullScreenLoader />;
  }
  return cookies.logged_in || user ? <Navigate to="/" /> : <Outlet />;
};

export default RequireUnauthenticated;
