import axios from "axios";

import { IEntry } from "./types";

const BASE_URL = process.env.REACT_APP_NEST_URI;

export const entryApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

entryApi.defaults.headers.common["Content-Type"] = "application/json";

export interface CreateEntryBody {
  userId: number;
  arrivalDate: Date;
  hoursDelay: number;
  comment: string;
}

export interface CreateAbsBody {
  userId: number;
  
  comment: string;
}
interface entriesFilters {
  date: Date;
  
}
export const getEntries = async (filterDate: Date): Promise<IEntry[]> => {
  const response = await entryApi.get<IEntry[]>("/entries", {
    params: {
      date: filterDate
    }
  });
  return response.data;
};
export const getEntries2 = async (filterDate: Date): Promise<IEntry[]> => {
  const response = await entryApi.get<IEntry[]>("/entries/dash", {
    params: {
      date: filterDate
    }
  });
  return response.data;
};
export const createEntry = async (body: CreateEntryBody): Promise<void> => {
  const response = await entryApi.post("/entries", body);
  return response.data;
};

export const createAbs = async (body: CreateAbsBody): Promise<void> => {
  const response = await entryApi.post("/entries/abs", body);
  return response.data;
};
export const updateEntry = async (params: { id: number; body: any }) => {
  
  const response = await entryApi.put<IEntry>(`entries/${params.id}`, params.body);
  return response.data;
};