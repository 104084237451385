import {
  HomeOutlined as HomeOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
  PeopleOutlined as PeopleOutlinedIcon,
} from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Role } from "../api/enum";
import { useStateContext } from "../context";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HistoryIcon from "@mui/icons-material/History";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsIcon from "@mui/icons-material/Settings";

function AppBar() {
  const { collapseSidebar } = useProSidebar();
  const stateContext = useStateContext();
  const location = useLocation();
  const user = stateContext.state.authUser;

  return (
    <div id="app" style={{ display: "flex" }}>
      <Sidebar
        style={{ height: "100vh", backgroundColor: "white" }}
        defaultCollapsed
      >
        <Menu
          style={{
            fontSize: "13px",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              collapseSidebar();
            }}
            style={{ fontSize: "13px" }}
          >
            <h2>Bonjour {user?.FirstName + "  " + user?.LastName}</h2>
          </MenuItem>

          {(user?.role === Role.ADMIN || user?.role === Role.SUPER_ADMIN) && (
            <MenuItem
              active={location.pathname === "/"}
              component={<Link to="/" />}
              icon={<HomeOutlinedIcon />}
            >
              Home
            </MenuItem>
          )}

          {(user?.role === Role.ADMIN || user?.role === Role.SUPER_ADMIN) && (
            <SubMenu label="Équipe" icon={<PeopleOutlinedIcon />}>
              <MenuItem
                active={location.pathname === "/teams"}
                component={<Link to="/teams" />}
                icon={<PeopleOutlinedIcon />}
              >
                Membre de l'équipe
              </MenuItem>
              <MenuItem
                active={location.pathname === "/listeofaccs"}
                component={<Link to="/teams/listeofaccs" />}
                icon={<RequestQuoteIcon />}
              >
                Demandes des accomptes
              </MenuItem>

              <MenuItem
                active={location.pathname === "/adminleave"}
                component={<Link to="/teams/adminleave" />}
                icon={<CalendarMonthIcon />}
              >
                Demandes des congés
              </MenuItem>
              <MenuItem
                active={location.pathname === "/absences"}
                component={<Link to="/teams/absences" />}
                icon={<CalendarMonthIcon />}
              >
                Liste des absences
              </MenuItem>
              <MenuItem
                active={location.pathname === "/delay"}
                component={<Link to="/entries/delay" />}
                icon={<RequestQuoteIcon />}
              >
                Retard du jour
              </MenuItem>
            </SubMenu>
          )}

          {user?.role === Role.CONTROLLER && (
            <SubMenu label="Équipe" icon={<PeopleOutlinedIcon />}>
              <MenuItem
                active={location.pathname === "/absences"}
                component={<Link to="/absences" />}
                icon={<RequestQuoteIcon />}
              >
                Absence du jour
              </MenuItem>
              <MenuItem
                active={location.pathname === "/delay"}
                component={<Link to="/entries/delay" />}
                icon={<RequestQuoteIcon />}
              >
                Retard du jour
              </MenuItem>
            </SubMenu>
          )}

          <SubMenu label="Mon Compte" icon={<SettingsIcon />}>
            <MenuItem
              active={location.pathname === "/liste"}
              component={<Link to="/liste" />}
              icon={<RequestQuoteIcon />}
            >
              Accompte sur salaire
            </MenuItem>

            <MenuItem
              active={location.pathname === "/listeofleaves"}
              component={<Link to="/listeofleaves" />}
              icon={<CalendarMonthIcon />}
            >
              Congé
            </MenuItem>
          </SubMenu>

          {user?.role === Role.SUPER_ADMIN && (
            <>
              <SubMenu label="Historique" icon={<HistoryIcon />}>
                <MenuItem
                  active={location.pathname === "/historiqueofaccs"}
                  component={<Link to="/teams/historiqueofaccs" />}
                  icon={<RequestQuoteIcon />}
                >
                  Liste des accomptes
                </MenuItem>

                <MenuItem
                  active={location.pathname === "/historiqueleave"}
                  component={<Link to="/teams/historiqueleave" />}
                  icon={<CalendarMonthIcon />}
                >
                  Liste des congés
                </MenuItem>
              </SubMenu>

              <SubMenu label="Jours Fériés" icon={<CalendarMonthIcon />}>
                <MenuItem
                  active={location.pathname === "/holidays"}
                  component={<Link to="/holidays" />}
                  icon={<RequestQuoteIcon />}
                >
                  Ajouter un jour férié
                </MenuItem>
              </SubMenu>
            </>
          )}
        </Menu>
      </Sidebar>
    </div>
  );
}

export default AppBar;
