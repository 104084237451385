import { Box, styled, Typography, Container, TextField } from '@mui/material';
import { Send, KeyboardArrowRight } from '@mui/icons-material';

import { FormProvider,  useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation,  useQueryClient } from '@tanstack/react-query';

import { LoadingButton as _LoadingButton } from '@mui/lab';
import { QUERY_KEYS } from '../../constants';

import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
//import FormInput from '../components/FormInput';


import { createAcc } from '../../api/accApi';



const styles = {
  btn: {
    fondtSize: 60,
    backgroundColor: 'black'
  },
  title: {
    textDecoration: 'underline',
    marginBottom: 20
  }
}

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: black;
  color: #2363eb;
  font-weight: 500;

  &:hover {
    background-color: green;
    transform: translateY(-2px);
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

const createSchema =  yup.object().shape({
  amountAcc: yup.number()
});


const Accompte = () => {
  const queryClient = useQueryClient();
  const methods = useForm({
     resolver: yupResolver(createSchema)
  });
  // API Get Current Logged-in user
  const navigate = useNavigate();
  //  API Create Mutation
  const { mutate: createacc, isLoading } = useMutation(createAcc, {
    onSuccess: () => {
      toast.success("Demande d'accompte envoyé avec succée");
      queryClient.invalidateQueries([QUERY_KEYS.ACCS])
      navigate('/liste')
    },
    onError: () => {
      toast.error("erreur");
    }
  });

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = methods;

  const onSubmitHandler = (values:any) => {
    // 👇 Executing the createAcc Mutation
    createacc(values);
  };

  return (
    <Container>
      <Typography
        sx={styles.title}
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom>
        Demander une accompte
      </Typography>

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          maxWidth="27rem"
          width="100%"
          sx={{
            backgroundColor: '#e5e7eb',
            p: { xs: '1rem', sm: '2rem' },
            borderRadius: 2
          }}>

          <TextField
            autoFocus
            margin="dense"
            {...register('amountAcc')}

            label="Montant de l'accompte"
            type="string"
            fullWidth
            variant="standard"
          />

          <br></br>

          <LoadingButton
            sx={styles.btn}
            type="submit"
            color="secondary"
            variant="contained"
            startIcon={<Send />}
            endIcon={<KeyboardArrowRight />}
            loading={isLoading}>
            Envoyer
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
};

export default Accompte;
